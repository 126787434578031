import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './css/sec4.module.css'
import img1 from '../../@assets/sec4/img1.png'
import img2 from '../../@assets/sec4/img2.png'
import img3 from '../../@assets/sec4/img3.png'

function Sec4() {
  const { t } = useTranslation()
  return (
    <>
      <section className={styles.wrapper}>
        <div className={styles.container}>
          {/* 타이틀박스 */}
          <div className={styles.title_wrap}>
            <div className={styles.title_container}>
              <h1 className={styles.title} data-aos="fade-right">
                ASSA Platform
              </h1>
              <p
                className={styles.p}
                data-aos="fade-right"
                data-aos-delay="250"
              >
                {t('sec4P')}
              </p>
            </div>
          </div>

          {/* 컨텐츠 박스 */}
          <div className={styles.contents_wrap}>
            <div className={styles.contents_container}>
              <li data-aos="fade" data-aos-delay="500">
                <div className={styles.imgBox}>
                  <img src={img1} alt="" />
                </div>
                <div className={styles.concept_wrap}>
                  <h1>
                    SNS Noraebang<span>Ver 1.0</span>
                  </h1>
                  <p>{t('sec4Contents1')}</p>
                </div>
              </li>

              <li data-aos="fade" data-aos-delay="750">
                <div className={styles.imgBox}>
                  <img src={img2} alt="" />
                </div>
                <div className={styles.concept_wrap}>
                  <h1>
                    Metaverse Noraebang<span>Ver 2.0</span>
                  </h1>
                  <p>Sing with other users on ASSA Metaverse</p>
                </div>
              </li>

              <li data-aos="fade" data-aos-delay="950">
                <div className={styles.imgBox}>
                  <img src={img3} alt="" />
                </div>
                <div className={styles.concept_wrap}>
                  <h1>
                    Metaverse Playground<span>Ver 3.0</span>
                  </h1>
                  <p>Play with global users on ASSA Metaverse Playground</p>
                </div>
              </li>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Sec4
