import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './app.module.css'
import Footer from './common/Footer'
import Header from './common/Header'
import Sec1 from './home/Sec1'
import Sec2 from './home/Sec2'
import Sec3 from './home/Sec3'
import Sec4 from './home/Sec4'
import Sec5 from './home/Sec5'
import Aos from 'aos'
import 'aos/dist/aos.css'

function App() {
  useEffect(() => {
    Aos.init({ duration: 700, easing: 'ease-in-out' })
  }, []) //단일 애니메어션 통제

  // 언어 변역 공통 핸들러
  const { i18n } = useTranslation()
  const [leng, setLeng] = useState('en')
  const changelanguageToKo = () => {
    i18n.changeLanguage('ko')
    setLeng('ko')
  }
  const changelanguageToEn = () => {
    i18n.changeLanguage('en')
    setLeng('en')
  }

  const scrollRef = useRef([])

  return (
    <div className={styles.wrapper}>
      <Header
        refs={scrollRef}
        leng={leng}
        ko={changelanguageToKo}
        en={changelanguageToEn}
      />
      <main className={styles.main}>
        <Sec1 refs={el => (scrollRef.current[0] = el)} />
        <Sec2 refs={el => (scrollRef.current[1] = el)} />
        <Sec3 />
        <Sec4 />
        <Sec5 refs={el => (scrollRef.current[2] = el)} />
      </main>
      <Footer leng={leng} refs={el => (scrollRef.current[3] = el)} />
    </div>
  )
}

export default App
