import React from 'react'
import styles from './common.module.css'
import logo from '../../@assets/common/fLogo.svg'
import tel1 from '../../@assets/common/tel_none.svg'
import tel2 from '../../@assets/common/tel_active.svg'
import med1 from '../../@assets/common/med_none.svg'
import med2 from '../../@assets/common/med_active.svg'
import twi1 from '../../@assets/common/twi_none.svg'
import twi2 from '../../@assets/common/twi_active.svg'
import cer1 from '../../@assets/common/cer_none.svg'
import cer2 from '../../@assets/common/cer_active.svg'
import cmc1 from '../../@assets/common/cmc_none.svg'
import cmc2 from '../../@assets/common/cmc_active.svg'
import coi1 from '../../@assets/common/coi_none.svg'
import coi2 from '../../@assets/common/coi_active.svg'
import bsc1 from '../../@assets/common/bsc_none.svg'
import bsc2 from '../../@assets/common/bsc_active.svg'
import { useTranslation } from 'react-i18next'

function Footer({ leng, refs }) {
  const { t } = useTranslation()
  return (
    <footer className={styles.f_wrapper} ref={refs}>
      <div className={styles.f_container}>
        <div className={styles.f1_wrap}>
          <img src={logo} alt="" />
          <p>{t('fP')}</p>
          <a href="mailto:info@assaplay.com">info@assaplay.com</a>
        </div>

        <div className={styles.f2_wrap}>
          <div className={styles.sns_wrap}>
            <a href="https://t.me/assa_play" className={styles.icon}>
              <img src={tel1} alt="" className={styles.icon_none} />
              <img src={tel2} alt="" className={styles.icon_active} />
            </a>

            {leng === 'en' ? (
              <a
                href="https://medium.com/assa-official-blog"
                className={styles.icon}
              >
                <img src={med1} alt="" className={styles.icon_none} />
                <img src={med2} alt="" className={styles.icon_active} />
              </a>
            ) : (
              <a
                href="https://medium.com/assa-officialkr-blog"
                className={styles.icon}
              >
                <img src={med1} alt="" className={styles.icon_none} />
                <img src={med2} alt="" className={styles.icon_active} />
              </a>
            )}

            <a href="https://twitter.com/assa_play" className={styles.icon}>
              <img src={twi1} alt="" className={styles.icon_none} />
              <img src={twi2} alt="" className={styles.icon_active} />
            </a>

            <a
              href="https://www.certik.com/projects/assa"
              className={styles.icon}
            >
              <img src={cer1} alt="" className={styles.icon_none} />
              <img src={cer2} alt="" className={styles.icon_active} />
            </a>

            <a
              href="https://coinmarketcap.com/currencies/assaplay/"
              className={styles.icon}
            >
              <img src={cmc1} alt="" className={styles.icon_none} />
              <img src={cmc2} alt="" className={styles.icon_active} />
            </a>

            <a
              href="https://www.coingecko.com/en/coins/assaplay"
              className={styles.icon}
            >
              <img src={coi1} alt="" className={styles.icon_none} />
              <img src={coi2} alt="" className={styles.icon_active} />
            </a>

            <a
              href="https://bscscan.com/token/0xa25d074d5300f9f997a76994840a3266a72f77e4"
              className={styles.icon}
            >
              <img src={bsc1} alt="" className={styles.icon_none} />
              <img src={bsc2} alt="" className={styles.icon_active} />
            </a>
          </div>

          <p>2022 ASSAPLAY PTE., LTD.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
