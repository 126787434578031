import React from 'react'
import styles from './css/sec5.module.css'
import news from './news.js'

function Sec5({ refs }) {
  return (
    <section className={styles.wrapper} ref={refs}>
      <div className={styles.container}>
        {/* 타이틀박스 */}
        <div className={styles.title_wrap}>
          <div className={styles.title_container}>
            <h1 className={styles.title} data-aos="fade">
              Latest News
            </h1>
          </div>
        </div>

        {/* 컨텐츠 박스 */}
        <div className={styles.contents_wrap}>
          <div className={styles.contents_container}>
            {news.map(item => {
              return (
                <li key={item} data-aos="zoom-in">
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <div className={styles.imgBox}>
                      <img src={item.img} alt="" />
                    </div>
                    <div className={styles.concept_wrap}>
                      <h1>{item.title}</h1>
                      <span>{item.daily}</span>
                    </div>
                  </a>
                </li>
              )
            })}
          </div>
        </div>

        {/* 버튼 */}
        <div className={styles.btn_wrap} data-aos="fade">
          <div className={styles.btn_container}>
            <a href="#">All Articles on Community</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Sec5
