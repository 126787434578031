import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
const resources = {
  ko: {
    translation: {
      sec1SubTitle: 'ASSA가 꿈꾸고 만들고자 하는 플랫폼',
      sec1P:
        '모든 구성원이 생산자, 소비자가 되어 보상받고 \n이익을 공유하는 고객 맞춤형 놀이터',
      sec2Title: 'Entertainment & Music\nMetaverse Playground',
      sec2SubTitle: '온오프라인을 아우르는',
      sec2P:
        'ASSA  Project는 2017년 팀 구성을 시작으로 국내외 협력 네트워트 강화와 글로벌 플랫폼 개발을 지속해왔으며, 특허 기반의  ASIC 기술과 35만 여곡에 이르는 보유 음원으로 다져진 글로벌 서비스 경험 및 노하우를 바탕으로 O2O 플랫폼의 활용을 넘어 ASSA만의 Metaverse Playground를 구축합니다. ',
      sec3Title: '탈중앙화된 블록체인 플랫폼의\n도입으로 정당한 보상 분배',
      sec3P:
        'ASSA는 플랫폼 참여자로 하여금 놀이와 문화적 창작활동이 경제활동으로 연결될 수 있는 Sing to Earn, Entertain to Earn을 구현할 것입니다. 모든 참여자는 ASSA플랫폼 내의 다양한 활동을 통해 정당한 보상을 분배 받습니다. ',
      sec4P:
        'ASSA는 모두가 즐길 수 있는 노래방 플랫폼의 연내 1차 출시를 목표로 개발중이며, 이후 메타버스 공간에 구현, 고도화해 나갈 예정입니다.\n 유저들이 놀고 즐기는 음악 컨텐츠 기반으로 한 NFT와 메타버스의 결합으로 크리에이터 이코노미를 창출, ASSA만의 독창성과 유연성을 만들어 낼 것 입니다.',
      sec4Contents1: '누구나 쉽게 즐길 수 있는 내 손안의 작은 노래방',

      fP: 'ASSA 관련 문의는 아래 메일로 보내주세요',
    },
  },
  en: {
    translation: {
      sec1SubTitle: 'The platform that ASSA dreams of and wants to create',
      sec1P:
        'a customized playground where members of the ecosystem, as producers and consumers, receive rewards and share profits.',
      sec2Title:
        'Entertainment & Music \nMetaverse Playground that \ncovers online and offline spaces.',
      sec2SubTitle: '',
      sec2P:
        'The ASSA Project has continued to strengthen domestic and international cooperation networks and develop global platforms since the formation of the team in 2017. Based on patent-based ASIC technology and global service experience and know-how with over 350,000 songs, ASSA builds its own Metaverse Playground beyond the use of O2O platform.',
      sec3Title:
        'Fair reward distribution through the introduction of a decentralized blockchain platform',
      sec3P:
        'ASSA enables platform participants to implement Sing to Earn and Entertain to Earn, where play and cultural creative activities can be directly linked to economic activities. All participants receive fair rewards through various activities within the ASSA platform.',
      sec4P:
        'ASSA is under development with the goal of releasing the first karaoke platform that everyone can enjoy within the year, and will be implemented and upgraded in the metaverse space. ASSA creates a creator economy by combining NFT and metaverse based on music content that users play and enjoy, creating the uniqueness and flexibility of ASSA services.',

      sec4Contents1: 'A small karaoke in my hand that anyone can easily enjoy',
      fP: 'Please contact us if you have any question.',
    },
  },
}
i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
})
export default i18n
