import React from 'react'
import styles from './css/sec2.module.css'
import { useTranslation } from 'react-i18next'
import img1 from '../../@assets/sec2/img1.png'
import img2 from '../../@assets/sec2/img2.png'
import img3 from '../../@assets/sec2/img3.png'
import img4 from '../../@assets/sec2/img4.png'

function Sec2({ refs }) {
  const { t } = useTranslation()
  return (
    <section className={styles.wrapper} ref={refs}>
      <div className={styles.container}>
        <div className={styles.title_wrap}>
          <h2 className={styles.subTitle} data-aos="fade-right">
            {t('sec2SubTitle')}
          </h2>
          <h1
            className={styles.title}
            data-aos="fade-right"
            data-aos-delay="250"
          >
            {t('sec2Title')}
          </h1>
          <p className={styles.p} data-aos="fade-right" data-aos-delay="500">
            {t('sec2P')}
          </p>
        </div>

        <div className={styles.img_wrap}>
          <img
            src={img4}
            alt=""
            className={styles.img4}
            data-aos="zoom-out"
            data-aos-delay="1200"
          />
          <img
            src={img3}
            alt=""
            className={styles.img3}
            data-aos="zoom-out"
            data-aos-delay="1000"
          />
          <img
            src={img2}
            alt=""
            className={styles.img2}
            data-aos="zoom-out"
            data-aos-delay="800"
          />
          <img
            src={img1}
            alt=""
            className={styles.img1}
            data-aos="zoom-in"
            data-aos-delay="650"
          />
        </div>
      </div>
    </section>
  )
}

export default Sec2
