import img1 from '../../@assets/sec5/img1.jpg'
import img2 from '../../@assets/sec5/img2.jpg'
import img3 from '../../@assets/sec5/img3.jpg'

const news = [
  {
    img: img1,
    title: 'ASSAPLAY Business Agreement with "Best Tech"',
    daily: 'Aug 29, 2022',
    link: 'https://english.etnews.com/20220828200001',
  },
  {
    img: img2,
    title: 'ASSAPLAY(ASSA) listed on Bitmart (ASSA/USDT)',
    daily: 'Aug 29, 2022',
    link: 'https://www.bitmart.com/trade/en?layout=basic&symbol=ASSA_USDT',
  },
  {
    img: img3,
    title: 'ASSAPLAY makes a Business Agreement with "sandoll Meta Lab"',
    daily: 'Mar 10, 2022',
    link: 'https://www.blockmedia.co.kr/archives/226370',
  },
]

export default news
