import React, { useEffect, useRef, useState } from 'react'
import styles from './common.module.css'
import logo from '../../@assets/common/logo.svg'
import { useMediaQuery } from 'react-responsive'

const throttle = function (callback, waitTime) {
  let timerId = null
  return e => {
    if (timerId) return
    timerId = setTimeout(() => {
      callback.call(this, e)
      timerId = null
    }, waitTime)
  }
}

function Header(props) {
  const mobile = useMediaQuery({ maxWidth: 600 })
  const nav = [
    { nav: 'About' },
    { nav: 'Vision' },
    { nav: 'News' },
    { nav: 'Contact' },
  ]

  const handleNavPosition = i => {
    props.refs.current[i].scrollIntoView({ behavior: 'smooth' })
  }

  // 스크롤 감지
  const [hide, setHide] = useState(false)
  const [pageY, setPageY] = useState(0)
  const documentRef = useRef(document)

  const handleScroll = () => {
    const { pageYOffset } = window
    const deltaY = pageYOffset - pageY
    const hide = pageYOffset !== 0 && deltaY <= 0
    setHide(hide)
    setPageY(pageYOffset)
  }

  const throttleScroll = throttle(handleScroll, 50)

  useEffect(() => {
    documentRef.current?.addEventListener('scroll', throttleScroll)
    return () =>
      documentRef.current?.removeEventListener('scroll', throttleScroll)
  }, [pageY])

  return (
    <div className={hide ? `${styles.hide}` : `${styles.layer_wrap}`}>
      <div className={styles.layer}>
        <header>
          <div className={styles.h_container}>
            <img src={logo} alt="" />

            {!mobile && (
              <div className={styles.nav_wrap}>
                {nav.map((item, i) => {
                  return (
                    <nav
                      key={i}
                      className={styles.nav}
                      onClick={() => {
                        handleNavPosition(i)
                      }}
                    >
                      {item.nav}
                    </nav>
                  )
                })}
              </div>
            )}

            <div className={styles.leng_wrap}>
              <button
                onClick={props.ko}
                style={
                  props.leng === 'ko' ? { color: '#fff' } : { color: '#F4BDB8' }
                }
              >
                KR
              </button>
              <span />
              <button
                onClick={props.en}
                style={
                  props.leng === 'en' ? { color: '#fff' } : { color: '#F4BDB8' }
                }
              >
                EN
              </button>
            </div>
          </div>
        </header>

        {mobile && (
          <div className={styles.mobile_nav_wrap}>
            {nav.map((item, i) => {
              return (
                <nav
                  key={i}
                  className={styles.nav}
                  onClick={() => {
                    handleNavPosition(i)
                  }}
                >
                  {item.nav}
                </nav>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
