import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './css/sec3.module.css'
import btnIcon from '../../@assets/sec3/down-img.svg'
import hoverIcon from '../../@assets/sec3/down-h-img.svg'
import img1 from '../../@assets/sec3/img1.png'
import img2 from '../../@assets/sec3/img2.png'
import { useMediaQuery } from 'react-responsive'

function Sec3({ leng, isRef }) {
  const { t } = useTranslation()
  const mobile = useMediaQuery({ maxWidth: 1000 })

  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.title_wrap}>
          <h1 className={styles.title} data-aos="fade-up">
            {t('sec3Title')}
          </h1>
          <p className={styles.p} data-aos="fade-up" data-aos-delay="250">
            {t('sec3P')}
          </p>
          {!mobile &&
            (leng === 'ko' ? (
              <a
                data-aos="fade-up"
                data-aos-delay="500"
                className={styles.btn}
                href="https://assaplay.com/pdf/Whitepaper.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <img src={btnIcon} alt="" className={styles.noneIcon} />
                <img src={hoverIcon} alt="" className={styles.hoverIcon} />
                More Details on Whitepaper
              </a>
            ) : (
              <a
                data-aos="fade-up"
                data-aos-delay="500"
                className={styles.btn}
                href="https://assaplay.com/pdf/Whitepaper_en.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <img src={btnIcon} alt="" className={styles.noneIcon} />
                <img src={hoverIcon} alt="" className={styles.hoverIcon} />
                More Details on Whitepaper
              </a>
            ))}
        </div>

        <div className={styles.img_wrap}>
          <img
            src={img1}
            alt=""
            className={styles.img1}
            data-aos="fade-right"
            data-aos-delay="700"
          />
          <img
            src={img2}
            alt=""
            className={styles.img2}
            data-aos="fade-left"
            data-aos-delay="1000"
          />
        </div>

        {mobile &&
          (leng === 'ko' ? (
            <a
              className={styles.mBtn}
              href="https://assaplay.com/pdf/Whitepaper.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <img src={hoverIcon} alt="" className={styles.hoverIcon} />
              More Details on Whitepaper
            </a>
          ) : (
            <a
              className={styles.mBtn}
              href="https://assaplay.com/pdf/Whitepaper_en.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <img src={hoverIcon} alt="" className={styles.hoverIcon} />
              More Details on Whitepaper
            </a>
          ))}
      </div>
    </section>
  )
}

export default Sec3
