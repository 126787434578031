import React from 'react'
import styles from './css/sec1.module.css'
import webImg from '../../@assets/sec1/sec1img1.png'
import mImg from '../../@assets/sec1/sec1img2.png'
import icon from '../../@assets/sec1/scrollIcon.svg'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

function Sec1({ refs }) {
  const { t } = useTranslation()
  const mobile = useMediaQuery({ maxWidth: 1000 })
  return (
    <section className={styles.wrapper} ref={refs}>
      <div className={styles.container}>
        <div className={styles.title_wrap}>
          <h1 className={styles.title} data-aos="fade-right">
            Global Entertainment Metaverse Platform on Music Theme
          </h1>
          <h2
            className={styles.subTitle}
            data-aos="fade-left"
            data-aos-delay="300"
          >
            {t('sec1SubTitle')}
          </h2>
          <p className={styles.p} data-aos="fade-right" data-aos-delay="500">
            {t('sec1P')}
          </p>
        </div>

        <div
          className={styles.img_wrap}
          data-aos="fade-up"
          data-aos-delay="1000"
        >
          {mobile ? <img src={mImg} alt="" /> : <img src={webImg} alt="" />}
        </div>

        {!mobile && (
          <div className={styles.scroll_wrap}>
            <img src={icon} alt="" />
            SCROLL
          </div>
        )}
      </div>
    </section>
  )
}

export default Sec1
